<template>
  <div class="select-wrapper">
    <multiselect
      v-model="model"
      :placeholder="placeholder"
      :options="option"
      :label="label"
      :track-by="trackBy"
      :multiple="true"
      @select="handleSelect"
      @close="closeHandle"
    >
      <span slot="noResult"
        >Oops! No elements found. Consider changing the search query.</span
      >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "multiselect-extend",
  components: {
    Multiselect,
  },
  data() {
    return {
      isSelectAll: false,
    };
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    trackBy: {
      type: String,
      required: true,
    },

    option: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleSelect(event) {
      if(this.model.length ===0){
        this.isSelectAll = false;
      }
      const currentItems = this.model.map(el => el[this.trackBy])
      if (event[this.label] === "Select all" && !this.isSelectAll) {
        const paymentPlans = this.option.filter(
          (el) => el[this.label] !== "Select all"
        );

        this.isSelectAll = true; // if select all is enabled already , dont allow to select all
        for (let item of paymentPlans) {

          // only add items not already selected
          if(!currentItems.includes(item[this.trackBy])){
            this.model.push(item);
          }

        }
      }else{
        this.model.push(event)
      }
    },
    closeHandle(_event) {
      // handles select all option from being in input
      this.model = this.model.filter((el) => el[this.label] !== "Select all");
    },
  },
  mounted() {
    const exists = this.option.find((el) => el[this.label] === "Select all");
    if (!exists) {
      this.option.push({
        [this.label]: "Select all",
      });
    }

    
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped>

</style>
